// MENU-FILTER
// ===== START =====
$('.project-menu_item').click(function () {
  clickMoreButtonCount = 0;

  $('.project-menu_item').each(function () {
    $(this).removeClass('active');
  });
  $(this).addClass('active');

  const id = $(this).attr('id');
  projectsVisibled(id);

  return false;
});
// ===== END =====

// РЕАЛИЗАЦИЯ КНОПКИ "ЕЩЕ" ДЛЯ ПРОЕКТОВ
// ===== START =====
var clickMoreButtonCount = 0;
$('#moreButton').click(function () {
  clickMoreButtonCount++;
  const id = $('.project-menu_item.active').attr('id');
  projectsVisibled(id);
});

// FIRST LOAD PROJECT ITEMS
$(document).ready(function () {
  visibleItemCount = 0;
  $('.project_item').each(function () {
    visibleItemCount++;
    if (visibleItemCount <= 4) return;
    $(this).fadeOut();
    $('#moreButton').fadeIn();
  });
});

function projectsVisibled(projectId) {
  if (projectId === 'projectsAll' && $('.project_item').length > 4) $('#moreButton').fadeIn();
  if (projectId !== 'projectsAll' && $('[data-project="' + projectId + '"]').length > 4) $('#moreButton').fadeIn();

  let visibleItemCount = 0;
  $('.project_item').each(function () {
    if (visibleItemCount >= 4 * (clickMoreButtonCount + 1)) {
      $(this).fadeOut();
      return;
    }

    if (projectId === 'projectsAll') {
      $(this).fadeIn();
      visibleItemCount++;
      if (visibleItemCount === $('.project_item').length) $('#moreButton').fadeOut();
      return;
    }

    const data = $(this).attr('data-project');
    if (projectId === data) {
      $(this).fadeIn();
      visibleItemCount++;
      if (visibleItemCount === $('[data-project="' + data + '"]').length) $('#moreButton').fadeOut();
    } else $(this).fadeOut();
  });
}
// ===== END =====
